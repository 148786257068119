'use client';

import { Button } from '@nextui-org/react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import SideArrowCircleLeft from '../icons/side-arrow-circle-left.svg';
import VLogoIcon from '../icons/v_logo.svg';

const SideBar = () => {
  const router = useRouter();
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  return (
    <>
      <div
        className="fixed left-0 top-0 w-[80px] h-screen bg-white shadow-xl z-[1000] transition-all duration-300"
        style={{ width: isSideBarOpen ? 274 : 80 }}
      >
        <div className="pt-[20px] flex justify-between">
          <div className="w-[80px] h-[35px] flex justify-center">
            <Image
              className="mx-[8px] cursor-pointer"
              src={VLogoIcon.src}
              width={30}
              height={35}
              alt="logo"
              onClick={() => {
                router.push('/');
              }}
            />
          </div>
          {isSideBarOpen && (
            <Button
              type="button"
              onClick={() => setIsSideBarOpen(!isSideBarOpen)}
              className="flex bg-transparent w-[40px] h-[40px] rounded-full p-0 min-w-0 mr-[20px] absolute top-[20px] right-0"
            >
              <Image
                width={24}
                height={24}
                src={SideArrowCircleLeft.src}
                alt="side-arrow-circle-left"
              />
            </Button>
          )}
        </div>
        {!isSideBarOpen && (
          <div className="absolute top-[45vh] left-0 w-full flex justify-center">
            <Button
              type="button"
              onClick={() => setIsSideBarOpen(!isSideBarOpen)}
              className="flex bg-transparent w-[40px] h-[40px] rounded-full p-0  min-w-0"
            >
              <Image
                width={24}
                height={24}
                src={SideArrowCircleLeft.src}
                alt="side-arrow-circle-left"
                className="rotate-180"
              />
            </Button>
          </div>
        )}
        {/* <LoginBtn isOpen={isSideBarOpen} /> */}
      </div>
      {/* <Modal
        classNames={{
          wrapper: `${isSideBarOpen ? 'w-[274px]' : 'w-[80px]'} !items-start justify-start bg-white shadow-2xl transition-all duration-300 overflow-hidden pointer-events-auto`,
          base: `w-[274px] inset-y-0 left-0 max-h-[none] rounded-l-none !justify-start`,
          body: 'h-screen w-full p-0 gap-0',
          backdrop: `${isSideBarOpen ? 'w-[274px]' : 'w-[80px]'} bg-transparent !fixed pointer-events-none`,
        }}
        isDismissable={false}
        shouldBlockScroll={false}
        isOpen={true}
        radius="none"
        scrollBehavior="inside"
        hideCloseButton
      >
        <ModalContent className="!m-0 !shadow-none">
          <ModalBody>
            <div className="pt-[20px] flex justify-between">
              <div>
                <Link href="/">
                  <Image
                    className="mx-[25px] cursor-pointer shrink-0"
                    src={VLogoIcon.src}
                    width={30}
                    height={35}
                    alt="logo"
                    onClick={() => {
                      router.push('/');
                    }}
                  />
                </Link>
              </div>
              {isSideBarOpen && (
                <Button
                  type="button"
                  onClick={() => setIsSideBarOpen(!isSideBarOpen)}
                  className="flex bg-transparent w-[40px] h-[40px] rounded-full p-0 min-w-0 mr-[20px]"
                >
                  <Image
                    width={24}
                    height={24}
                    src={SideArrowCircleLeft.src}
                    alt="side-arrow-circle-left"
                  />
                </Button>
              )}
            </div>
            {!isSideBarOpen && (
              <div className="absolute top-[49vh] left-0 w-full flex justify-center">
                <Button
                  type="button"
                  onClick={() => setIsSideBarOpen(!isSideBarOpen)}
                  className="flex bg-transparent w-[40px] h-[40px] rounded-full p-0  min-w-0"
                >
                  <Image
                    width={24}
                    height={24}
                    src={SideArrowCircleLeft.src}
                    alt="side-arrow-circle-left"
                    className="rotate-180"
                  />
                </Button>
              </div>
            )}
          </ModalBody>
        </ModalContent>
      </Modal> */}
    </>
  );
};

export default SideBar;
