import { SearchMethod, SearchMode } from '../models/model';
import { getItem } from '../utils/storage';

const FUNCTION_BASE_URL =
  'https://gemmed-unctions-hvhludohph.ap-northeast-2.fcapp.run'; // PROD API

// const FUNCTION_BASE_URL =
//   'https://gemmed-unctions-lewhoxyzwh.ap-northeast-2.fcapp.run'; // TEST API

interface FilterItem {
  yearFrom?: number;
  fundCategory?: string;
  projectCategory?: string;
  subject?: string;
}

interface SearchItem {
  query: string;
  filter?: FilterItem;
  searchMethod: SearchMethod;
}

interface BaseParams {
  paper?: any;
  query?: string;
  [key: string]: any;
}

const makeRequest = async (
  endpoint: string,
  params: BaseParams
): Promise<Response> => {
  const token = getItem('token');
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    body: JSON.stringify(params),
  };

  try {
    const response = await fetch(`${FUNCTION_BASE_URL}${endpoint}`, options);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error(`Request to ${endpoint} failed:`, error);
    throw error;
  }
};

export const sendFeedback = async (params: { feedback: string }) => {
  return makeRequest('/feedback', params);
};

export const fetchPreviousPapers = async (params: { paper: any }) => {
  return makeRequest('/paper/previous_papers', params);
};

export const getQueryContext = async (
  endpoint: string,
  params: { query: string }
) => {
  const token = getItem('token');
  const option = {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
  return fetch(`${FUNCTION_BASE_URL}/query/${endpoint}`, option);
};

export const getRelatedSearch = async (params: {
  answer: string;
  queryZh: string;
}) => {
  return makeRequest('/query/related_search', params);
};

export const getBulletPointsExpansion = async (params: {
  targetBltptTitle: string;
  allTitles: string[];
  papers: any[];
  rawQuery: string;
}) => {
  return makeRequest('/bltpt/expansion', params);
};

export const getBulletPointsLiteraturePreview = async (params: {
  bltpt: string;
  subtitles: string;
  papers: any[];
  lang: 'zh' | 'en';
}) => {
  return makeRequest('/bltpt/literature_review', params);
};

export const getSearchResults = async (
  params: SearchItem,
  type: SearchMode
) => {
  const path = `/search/${type}`;
  try {
    umami.track('search', {
      query: params.query,
      type,
    });
  } catch {}
  return makeRequest(path, params);
};

export const getSummaryBulletPoints = async (params: {
  papers: any[];
  queryZh: string;
  rawQuery: string;
}) => {
  return makeRequest('/bltpt/gen_v2', params);
};

export const getSummaryAnalysis = async (params: {
  papers: any[];
  queryZh: string;
  rawQuery: string;
}) => {
  return makeRequest('/bltpt/analysis', params);
};

export const getResponsePedia = async (params: {
  papers: {
    id: string;
  }[];
  queryZh: string;
}) => {
  return makeRequest('/paper/response', params);
};

export const fetchAbstractAndTranslation = async (paper: any) => {
  return makeRequest('/paper/abstract_and_translation', { paper });
};

export const fetchJournalInfo = async (paper: any) => {
  return makeRequest('/paper/journal_info', { paper });
};

export const fetchReadingGuide = async (paper: any) => {
  return makeRequest('/paper/reading_guide', { paper });
};

export const fetchFundSimiliar = async (paper: any) => {
  return makeRequest('/paper/similar_fund', { paper });
};

export const fetchSimiliar = async (paper: any) => {
  return makeRequest('/paper/similar_papers', { paper });
};

export const fetchResponses = async (params) => {
  return makeRequest('/paper/response', params);
};

export const fetchTitleTranslation = async (paper: any) => {
  return makeRequest('/paper/title_translation', { paper });
};

export const fetchCategoryCircleCharts = async (params: SearchItem) => {
  return makeRequest('/bi/category_circle_charts', params);
};

/**
 * -----分割线-----此内容以下是登录、支付相关api
 */
const BASE_API = 'https://xiangqian.tech/prod';

// 获取登录url
export const fetchLoginQrcode = async () => {
  const apiUrl = `${BASE_API}/api/v1/wechat-official-account/get-qrcode`;
  return fetch(apiUrl);
};

// 获取登录结果
export const fetchLoginStatus = async (params: { ticket: string }) => {
  const apiUrl = `${BASE_API}/api/v1/wechat-official-account/get-status`;
  const query = new URLSearchParams(params);

  return fetch(`${apiUrl}?${query.toString()}`);
};

// 获取支付url
export const fetchPayQrcode = async (params: { product_id: string }) => {
  const token = getItem('token');
  const apiUrl = `${BASE_API}/api/v1/pay/order`;
  const query = new URLSearchParams(params);
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };

  return fetch(`${apiUrl}?${query.toString()}`, options);
};

// 获取支付结果
export const fetchPayStatus = async (params: { out_trade_no: string }) => {
  const token = getItem('token');
  const apiUrl = `${BASE_API}/api/v1/pay/state`;
  const query = new URLSearchParams(params);
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };

  return fetch(`${apiUrl}?${query.toString()}`, options);
};

// 获取商品列表
export const fetchTradeList = async () => {
  const token = getItem('token');
  const apiUrl = `${BASE_API}/api/v1/pay/product`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };

  return fetch(apiUrl, options);
};

// 获取用户信息
export const fetchUserInfo = async () => {
  const token = getItem('token');
  const apiUrl = `${BASE_API}/api/v1/user/get`;
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };

  return fetch(apiUrl, options);
};

export const fetchYearGraphCharts = async (params: SearchItem) => {
  return makeRequest('/bi/year_graph_charts', params);
};

export const fetchBarCharts = async (params: SearchItem) => {
  return makeRequest('/bi/subject_and_category_bar_charts', params);
};
