import {
  action,
  computed,
  configure,
  flow,
  makeObservable,
  observable,
} from 'mobx';
import { BarChartProps } from '../components/barChart';
import { CircleChartProps } from '../components/circleChart';
import { GraphChartProps } from '../components/yearGraph';
import {
  getQueryContext,
  getRelatedSearch,
  getResponsePedia,
  getSearchResults,
  getSummaryAnalysis,
  getSummaryBulletPoints,
} from '../service';
export const PAGESIZE = 10;

export enum SearchMethod {
  KEYWORD = 'keyword',
  AI = 'ai',
}

export enum SearchMode {
  EN = 'paper_en',
  ZH_CN = 'paper_zh',
  FUND = 'fund_zh',
}

export enum SortMode {
  DEFAULT = 'default',
  RELEVANCE = 'relevance',
  TIME = 'time',
  LEVEL = 'level',
  CITATION = 'citation',
}

export interface IPopoverInfo {
  barCharts?: BarChartProps[];
  graphCharts?: GraphChartProps[];
  circleCharts?: CircleChartProps[];
  text?: string;
  key: string;
  paperList: any[];
  title: string;
  subtitle?: string;
}

export interface SearchFilter {
  yearFrom: number | null;
  fundCategory: string | null;
  projectCategory: string | null;
  subject: string | null;
}

export interface QueryContext {
  activeTab: 'concept' | 'background' | 'terms';
  concept: string;
  background: string;
  terms: string;
  relatedSearch: string[];
}

interface IEntityInfo {
  searchMethod: SearchMethod;
  isLoadingEntities: boolean;
  queryZh: string;
  entities: any[];
  isLoadingPrefix: boolean;
  isLoadingBulletPoints: boolean;
  bulletPointsPrefix: string;
  bulletPoints: IPopoverInfo[];
}

class MainState {
  mode: SearchMode;
  sortMode: SortMode = SortMode.DEFAULT;
  pageIndex = 1;
  question: string = '';
  idFilterText: string = '';
  paperIdFilter: string[] = [];
  searchMethod: SearchMethod = SearchMethod.AI;
  entityInfoMap: Record<SearchMode, IEntityInfo> = {
    [SearchMode.EN]: {
      searchMethod: SearchMethod.AI,
      isLoadingEntities: false,
      queryZh: '',
      entities: [],
      isLoadingPrefix: false,
      isLoadingBulletPoints: false,
      bulletPointsPrefix: '',
      bulletPoints: [],
    },
    [SearchMode.ZH_CN]: {
      searchMethod: SearchMethod.AI,
      isLoadingEntities: false,
      queryZh: '',
      entities: [],
      isLoadingPrefix: false,
      isLoadingBulletPoints: false,
      bulletPointsPrefix: '',
      bulletPoints: [],
    },
    [SearchMode.FUND]: {
      searchMethod: SearchMethod.AI,
      isLoadingEntities: false,
      queryZh: '',
      entities: [],
      isLoadingPrefix: false,
      isLoadingBulletPoints: false,
      bulletPointsPrefix: '',
      bulletPoints: [],
    },
  };
  isLoadingQueryContext: {
    concept: boolean;
    terms: boolean;
    background: boolean;
    relatedSearch: boolean;
  } = {
    concept: false,
    terms: false,
    background: false,
    relatedSearch: false,
  };
  searchFilter: SearchFilter = {
    yearFrom: null,
    fundCategory: null,
    projectCategory: null,
    subject: null,
  };
  queryContext: QueryContext = {
    activeTab: 'terms',
    concept: '',
    background: '',
    terms: '',
    relatedSearch: [],
  };

  constructor() {
    configure({
      enforceActions: 'always',
    });
    this.mode = SearchMode.EN;
    makeObservable(this, {
      mode: observable,
      searchMethod: observable,
      sortMode: observable,
      pageIndex: observable,
      question: observable,
      showPapers: computed,
      entityInfoMap: observable,
      isLoadingPapers: computed,
      isLoadingQueryContext: observable,
      queryContext: observable,
      updateBulletPoint: action,
      updateEntities: action,
      fetchSearchResults: flow,
      fetchSearchPapers: flow,
      fetchRelatedSearch: flow,
      fetchSummaryBulletPoints: flow,
      fetchSummaryAnalysis: flow,
      fetchQueryContext: flow,
      fetchResponsePedia: flow,
      updateQuestion: action,
      changeMode: action,
      changeSortMode: action,
      changePageIndex: action,
      paperIdFilter: observable,
      idFilterText: observable,
      setEntityIdFilter: action,
      searchFilter: observable,
      setSearchFilter: action,
      currentNumPages: computed,
      killFundPapers: action,
      clearQueryContext: action,
      changeSummaryTab: action,
      changeSearchMethod: action,
    });
  }

  public changeSearchMethod(method: SearchMethod) {
    this.searchMethod = method;
  }

  public setSearchFilter(
    yearFrom: number | null,
    fundCategory: string | null,
    projectCategory: string | null,
    subject: string | null
  ) {
    this.searchFilter.yearFrom = yearFrom;
    this.searchFilter.fundCategory = fundCategory || null;
    this.searchFilter.projectCategory = projectCategory || null;
    this.searchFilter.subject = subject || null;
  }

  public get isLoadingPapers() {
    return (
      this.entityInfoMap[SearchMode.EN].isLoadingEntities ||
      this.entityInfoMap[SearchMode.ZH_CN].isLoadingEntities ||
      this.entityInfoMap[SearchMode.FUND].isLoadingEntities
    );
  }

  public updateQuestion(question: string) {
    this.question = question;
    Object.values(SearchMode).forEach((mode) => {
      this.entityInfoMap[mode] = {
        searchMethod: SearchMethod.AI,
        queryZh: '',
        entities: [],
        isLoadingEntities: false,
        isLoadingPrefix: false,
        isLoadingBulletPoints: false,
        bulletPointsPrefix: '',
        bulletPoints: [],
      };
    });
    this.setEntityIdFilter([], '');
    this.pageIndex = 1;
    this.clearQueryContext();
    this.setSearchFilter(null, null, null, null);
  }

  public get currentEntityInfo() {
    return this.entityInfoMap[this.mode];
  }

  public get currentNumPages() {
    const theSet = new Set(this.paperIdFilter);
    let newList = this.currentEntityInfo.entities;
    if (theSet.size > 0) {
      newList = newList.filter((item) => theSet.has(item.id));
    }
    return Math.ceil(newList.length / PAGESIZE);
  }

  public get showPapers() {
    const currentPapers = this.currentEntityInfo.entities;
    let newList = [...currentPapers];

    const sortFunctions = {
      [SortMode.TIME]: (a: any, b: any) => b.year - a.year,
      [SortMode.RELEVANCE]: (a: any, b: any) => b.relevance - a.relevance,
      [SortMode.CITATION]: (a: any, b: any) =>
        b.paperData.citationCount - a.paperData.citationCount,
      [SortMode.DEFAULT]: () => 0,
    };

    const sortedList =
      this.sortMode === SortMode.DEFAULT
        ? newList
        : [...newList].sort(sortFunctions[this.sortMode]);

    const filteredList =
      this.paperIdFilter.length > 0
        ? sortedList.filter((item) => this.paperIdFilter.includes(item.id))
        : sortedList;

    const start = (this.pageIndex - 1) * PAGESIZE;
    return filteredList.slice(start, start + PAGESIZE);
  }

  public changeMode(mode: SearchMode) {
    this.setEntityIdFilter([], '');
    this.mode = mode;
  }

  public changeSortMode(sortMode: SortMode) {
    this.sortMode = sortMode;
  }

  public changePageIndex(pageIndex: number) {
    this.pageIndex = pageIndex;
  }

  public setEntityIdFilter(ids: string[], text: string) {
    if (this.paperIdFilter !== ids || this.idFilterText !== text) {
      this.pageIndex = 1;
    }
    this.paperIdFilter = ids;
    this.idFilterText = text;
  }

  public updateBulletPoint(key: string, bulletPoint: IPopoverInfo) {
    this.currentEntityInfo.bulletPoints =
      this.currentEntityInfo.bulletPoints.map((item) =>
        item.key === key ? bulletPoint : item
      );
  }

  public updateEntities(entities: any[]) {
    const processedMap = new Map(entities.map((entity) => [entity.id, entity]));

    this.currentEntityInfo.entities = this.currentEntityInfo.entities.map(
      (entity) =>
        processedMap.has(entity.id)
          ? { ...entity, ...processedMap.get(entity.id)! }
          : entity
    );
  }

  public *fetchResponsePedia() {
    const queryZh = this.currentEntityInfo.queryZh || '';
    const fetchList = this.showPapers.filter((element) => !element.response);
    if (fetchList.length === 0) return;

    const res = yield getResponsePedia({
      queryZh,
      papers: fetchList,
    });

    if (!res.ok) return;

    const { papers } = yield res.json();
    this.updateEntities(papers);
  }

  public *fetchRelatedSearch() {
    if (this.isLoadingQueryContext.relatedSearch) {
      return;
    }
    if (this.queryContext.relatedSearch.length > 0) {
      return;
    }
    try {
      this.isLoadingQueryContext.relatedSearch = true;
      const queryZh = this.currentEntityInfo.queryZh;

      const listRes = yield getRelatedSearch({
        answer: this.currentEntityInfo.bulletPointsPrefix,
        queryZh: queryZh,
      });
      if (!listRes.ok) {
        return;
      }
      const data = (yield listRes.json()) as string[];
      this.queryContext.relatedSearch = [...data];
    } catch (e) {
    } finally {
      this.isLoadingQueryContext.relatedSearch = false;
    }
  }

  public *fetchSummaryBulletPoints() {
    if (this.currentEntityInfo.isLoadingBulletPoints) {
      return;
    }
    if (this.currentEntityInfo.bulletPoints.length > 0) {
      return;
    }
    const { queryZh, entities } = this.currentEntityInfo;

    this.currentEntityInfo.isLoadingBulletPoints = true;
    try {
      const res = yield getSummaryBulletPoints({
        papers: entities,
        queryZh,
        rawQuery: this.question,
      });
      if (!res.ok) {
        return;
      }
      const data = (yield res.json()) as {
        items: {
          title: string;
          subtitle: string;
          paperFilter: string[];
        }[];
      };
      this.currentEntityInfo.bulletPoints = data.items.map((item, index) => {
        const matches = item.paperFilter;
        const list = matches.reduce<string[]>((arr, element) => {
          const paper = entities.find((item) => item.id === element);
          if (paper) {
            return [...arr, paper];
          }
          return arr;
        }, []);
        return {
          text: undefined,
          key: `${this.mode}-${index}`,
          paperList: list,
          title: item.title,
          subtitle: item.subtitle,
          graphCharts: undefined,
          circleCharts: undefined,
          barCharts: undefined,
        };
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.currentEntityInfo.isLoadingBulletPoints = false;
    }
  }

  public *fetchSummaryAnalysis() {
    if (this.currentEntityInfo.isLoadingPrefix) {
      return;
    }
    if (this.currentEntityInfo.bulletPointsPrefix) {
      return;
    }
    const { queryZh, entities } = this.currentEntityInfo;

    try {
      this.currentEntityInfo.isLoadingPrefix = true;
      const res = yield getSummaryAnalysis({
        papers: entities,
        queryZh,
        rawQuery: this.question,
      });
      if (!res.ok) {
        return;
      }
      const data = yield res.json();
      this.currentEntityInfo.bulletPointsPrefix = data;
    } catch (error) {
      console.log(error);
    } finally {
      this.currentEntityInfo.isLoadingPrefix = false;
    }
  }

  public *fetchQueryContext(type: 'background' | 'terms' | 'concept') {
    if (this.isLoadingQueryContext[type]) {
      return;
    }
    if (this.queryContext[type]) {
      return;
    }
    this.isLoadingQueryContext[type] = true;

    try {
      const res = yield getQueryContext(type, {
        query: this.question,
      });
      if (!res.ok) {
        return;
      }
      const data = yield res.json();
      this.queryContext[type] = data;
    } catch (error) {
    } finally {
      this.isLoadingQueryContext[type] = false;
    }
  }

  public *fetchSearchResults(type: SearchMode) {
    if (this.currentEntityInfo.isLoadingEntities) {
      return;
    }
    if (this.currentEntityInfo.entities.length > 0) {
      return;
    }
    this.currentEntityInfo.isLoadingEntities = true;
    try {
      const res = yield getSearchResults(
        {
          query: this.question,
          searchMethod: this.searchMethod,
          filter: this.searchFilter,
        },
        type
      );
      if (!res.ok) {
        return;
      }
      const data = yield res.json();
      this.currentEntityInfo.queryZh = data.queryZh;
      this.currentEntityInfo.entities = data.papers;
      this.currentEntityInfo.searchMethod = this.searchMethod;
    } catch (error) {
    } finally {
      this.currentEntityInfo.isLoadingEntities = false;
    }
  }

  public *fetchSearchPapers() {
    yield Promise.all([
      this.fetchSearchResults(this.mode),
      this.fetchQueryContext(this.queryContext.activeTab),
    ]);
    this.fetchSummaryAnalysis();
    this.fetchResponsePedia();
    this.fetchSummaryBulletPoints();
    this.fetchRelatedSearch();
  }

  public killFundPapers() {
    this.currentEntityInfo.entities = [];
    this.currentEntityInfo.queryZh = '';
  }

  public changeSummaryTab(tab: 'concept' | 'background' | 'terms') {
    this.queryContext.activeTab = tab;
    this.fetchQueryContext(tab);
  }

  public clearQueryContext() {
    this.queryContext.activeTab = 'terms';
    this.queryContext.relatedSearch = [];
    this.queryContext.terms = '';
    this.queryContext.concept = '';
    this.queryContext.background = '';
  }
}

const mainState = new MainState();

export default mainState;
